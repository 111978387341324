
import { useQrDecode } from 'react-qr-hooks';
import React, { useState } from 'react'
import QrcodeDecoder from 'qrcode-decoder';

export const QrReader = () => {
  const [first, setfirst] = useState(null)
  // const [first_, setfirst_] = useState("- -")
  const imagenFile = async (e) => {
    console.time("imagenFile")
    // console.log(file)
    const qr = new QrcodeDecoder();
    var reader = new FileReader()
    reader.onload = function(e) {
      setfirst(e.target.result);
      qr.decodeFromImage(e.target.result).then(
        (res) => {
          console.log("Decodificado",res);
          console.timeEnd("imagenFile")
          // setfirst(res.data)
          alert(res.data)
        }
      );
    }
    reader.readAsDataURL(e.target.files[0])
  }

    const decoded = useQrDecode(first===null?"":first);
    console.log("decoded", decoded);
  return (<>
    <div>QrReader</div>
    <input type="file" accept="image/*" onChange={imagenFile} />
    <h1>{decoded===null?"no":decoded}</h1>
  </>)
}
