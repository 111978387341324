import './App.css';
import { QrReader } from './QrReader';

function App() {
  return (
   <>
    <h5>ReadQR</h5>
    <QrReader />
   </>
  );
}

export default App;
